import { Close20 } from "@carbon/icons-react";
import { Dialog, Transition } from "@headlessui/react";
import cn from "classnames";
import { Fragment } from "react";

import Title from "~/components/ui/Title";

interface IProps {
  open: boolean;
  setOpen: (b: boolean) => void;
  title?: string;
  children: React.ReactNode;
  width?: "base" | "large" | "full";
  unpadded?: boolean;
  closeable?: boolean;
}

export default function Modal({
  open,
  setOpen,
  title,
  children,
  width = "base",
  unpadded = false,
  closeable = true,
}: IProps) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        static
        open={open}
        onClose={() => setOpen(false)}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={cn(
                "relative inline-flex flex-col w-full my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl",
                {
                  "max-w-3xl": width === "base",
                  "max-w-5xl": width === "large",
                  "max-w-[80%]": width === "full",
                  "p-6": !unpadded,
                }
              )}
            >
              {title && (
                <Title className="pb-6" level={3}>
                  {title}
                </Title>
              )}

              {closeable && (
                <button className="absolute top-0 right-0 z-10 p-4" onClick={() => setOpen(false)}>
                  <Close20 className="text-gray-500" />
                </button>
              )}

              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
