import { useState } from "react";

import useVizState from "~/hooks/core/useVizState";

const useAdminImpersonate = () => {
  const resetVizState = useVizState(state => state.reset);
  const [impersonateState, setImpersonateState] = useState<"idle" | "pending" | "error">("idle");

  const onImpersonate = async (userEmail: string, callbackUrl = "/") => {
    setImpersonateState("pending");
    resetVizState();
    await fetch("/api/admin/impersonate", {
      body: JSON.stringify({ userEmail }),
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    });
    window.location.assign(callbackUrl); // not using router to avoid client-side routing and triggering a full refresh (including the session)
  };

  const exitImpersonation = async () => {
    resetVizState();
    await fetch("/api/admin/impersonate", {
      method: "DELETE",
    });
    window.location.assign("/admin"); // not using router to trigger a full refresh
  };

  return { onImpersonate, impersonateState, exitImpersonation };
};

export default useAdminImpersonate;
