import { round } from "lodash";

import type { IndicatorType } from "~/lib/genu";

export const haToSqM = 10000;
export const sqMToHa = 1 / haToSqM;

// an area already in ha
export function formatArea(area: number): string {
  return `${formatNumber(area)} ha`;
}

// a distance in meters
export function formatDistance(distance: number): string {
  if (distance > 5000) return (distance / 1000).toFixed(0) + " km";
  if (distance > 1000) return (distance / 1000).toFixed(1) + " km";
  return distance.toFixed(0) + " m";
}

export function formatPercentageNumber(value: number, locale?: string): string {
  const percent = 100 * value;
  return isNaN(percent)
    ? "--"
    : new Intl.NumberFormat(locale ?? "en-US").format(
        round(percent, percent > 0.5 || percent === 0 ? 0 : 1)
      );
}

export function formatProportion(value: number, locale?: string): string {
  return `${formatPercentageNumber(value, locale)}%`;
}

export function formatLocation(location: GeoJSON.Point, precision = 5) {
  return `Lon ${location.coordinates[0].toFixed(precision)} Lat ${location.coordinates[1].toFixed(
    precision
  )}`;
}

export function formatAddress({
  address,
  postalCode,
  locality,
  country,
}: {
  address: string | null;
  postalCode: string | null;
  locality: string | null;
  country: string | null;
}) {
  return [address, `${postalCode ?? ""} ${locality ?? ""}`, country]
    .map((value) => value?.trim())
    .filter((value) => !!value)
    .join(", ");
}

export const formatRating = (rating: number) => {
  return `${Math.round(rating)}`;
};

export const formatNumberWithSign = (rating: number) => {
  const rounded = Math.round(rating);
  if (isNaN(rounded)) {
    return "--";
  } else if (rounded > 0) {
    return `+${rounded}`;
  } else if (rounded === 0) {
    return "0";
  } else {
    return rounded.toString();
  }
};

export function formatNumber(value: number, locale?: string): string {
  return isNaN(value)
    ? "--"
    : value >= 1e5
      ? value.toExponential(2)
      : new Intl.NumberFormat(locale ?? "en-US").format(
          round(value, value > 10 ? 0 : value > 1 ? 1 : value === 0 ? 0 : 2)
        );
}

export const formatDecimalNumber = (value: number, locale?: string, precision = 0) =>
  new Intl.NumberFormat(locale ?? "en-US").format(round(value, precision));

export function formatShortHsuCode(code: string): string {
  const parts = code.split(/[._]/);
  return parts[parts.length - 1];
}

export function splitNormalized(value: IndicatorType): IndicatorType;
export function splitNormalized(value: string): string;
export function splitNormalized(value: string) {
  return value.endsWith("_normalized") ? value.slice(0, -"_normalized".length) : value;
}

export function addNormalized(value: string) {
  return value.endsWith("_normalized") ? value : `${value}_normalized`;
}

export function unaccent(str: string) {
  /// see https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}
