import "~/styles/main.css";
import "react-notion-x/src/styles.css";
import "katex/dist/katex.min.css";

import type { AppProps } from "next/app";
import { Router, useRouter } from "next/dist/client/router";
import type { Session } from "next-auth";
import { Provider, signIn, useSession } from "next-auth/client";
import NProgress from "nprogress";
import type { FC, PropsWithChildren } from "react";
import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { mutate, SWRConfig } from "swr";

import { ImpersonateControl } from "~/components/admin";
import { EasterEgg, ErrorBoundary, Head, NotificationContainer } from "~/components/common";
import Analytics from "~/components/common/Analytics";
import { DemoModal } from "~/components/demo";
import { useAnalytics, useCurrentUserProfile } from "~/hooks";
import { identifiers } from "~/lib/analytics";
import fetcher from "~/lib/fetcher";
import initializeI18n from "~/lib/i18n";
import { initCrisp } from "~/lib/support";

// Initializations
const i18n = initializeI18n();
NProgress.configure({ showSpinner: false });

// Bind router events for NProgress and analytics
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const Noop: FC<PropsWithChildren> = ({ children }) => <>{children}</>;

function App({ Component, pageProps }: AppProps<{ session: Session }>) {
  const router = useRouter();
  const [i18nInitialized, setI18nInitialized] = useState<boolean>(false);

  useEffect(() => {
    document.body.classList?.remove("loading");

    // initialize Crisp
    initCrisp();

    // in demo mode, anonymously sign in the user.
    // The session will be intercepted and we'll act as logged in with
    // demo@example.com
    if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_DEMO_MODE === "true") {
      signIn("demo", { redirect: false }).then(() => mutate("/api/me"));
    }
  }, []);

  // change the i18n locale when the router says so, or right after initialization.
  useEffect(() => {
    if (router.locale) {
      i18n.changeLanguage(router.locale);
    }

    i18n.on("initialized", () => setI18nInitialized(true));
    return i18n.off("initialized");
  }, [i18nInitialized, router.locale]);

  const Layout = (Component as any).Layout || Noop;
  const Breadcrumbs = (Component as any).Breadcrumbs || null;

  return (
    <Provider session={pageProps.session}>
      <I18nextProvider i18n={i18n}>
        <SWRConfig value={{ fetcher }}>
          <Head />
          {typeof window !== "undefined" && <Analytics />}
          <Auth>
            <Layout Breadcrumbs={Breadcrumbs}>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
              <EasterEgg />
              <NotificationContainer />
            </Layout>
          </Auth>
          {process.env.NEXT_PUBLIC_DEMO_MODE === "true" && <DemoModal />}
        </SWRConfig>
      </I18nextProvider>
    </Provider>
  );
}

export default App;

function Auth({ children }: { children: JSX.Element }) {
  const [session, loading] = useSession();
  const profile = useCurrentUserProfile();
  const { identifyUser } = useAnalytics();
  const isUser = !!session?.user;

  React.useEffect(() => {
    if (loading) return; // Do nothing while loading
  }, [isUser, loading]);

  useEffect(() => {
    const { userId, traits } = identifiers(session, profile);
    identifyUser(userId, traits);
  }, [identifyUser, session, profile]);

  return (
    <>
      {isUser && session?.impersonatorUserId && <ImpersonateControl session={session} />}
      {children}
    </>
  );
}
