import Image from "next/image";
import Link from "next/link";
import notFoundImage from "public/images/illustrations/not-found.png";
import { useTranslation } from "react-i18next";

import { Button, Title } from "~/components/ui";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-6">
      <Title>{t("error.notFound", "This page does not exist")}</Title>
      <div className="relative w-64 h-64 max-w-full">
        <Image src={notFoundImage} alt="Not found" layout="fill" objectFit="contain" />
      </div>
      <Link href="/" passHref>
        <Button Component="a" type="primary">
          {t("error.homelink", "Back to the homepage.")}
        </Button>
      </Link>
    </div>
  );
}
